<template>
    <div class="password-login login-main">
        <div
            v-if="templateInfo.loginMethod.includes('1') && templateInfo.loginMethod.includes('4')"
            class="login-header">
            <div
                class="header-item"
                :class="{ 'header-item_activated': loginForm.curLoginMethod === '1' }"
                @click="handleLoginMethodSwitch('1')"
            >账号密码登录</div>
            <div class="header-line">|</div>
            <div
                class="header-item"
                :class="{ 'header-item_activated': loginForm.curLoginMethod === '4' }"
                @click="handleLoginMethodSwitch('4')"
            >手机快捷登录</div>
        </div>
        <!-- 密码登录 -->
        <el-form
            v-if="loginForm.curLoginMethod === '1'"
            ref="loginForm"
            class="login-form login-form_password"
            :model="loginForm"
            :rules="loginRules"
            label-width="0px">
            <!-- 账号 -->
            <el-form-item prop="userName">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="loginForm.userName" placeholder="账号" clearable></el-input>
                </div>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item prop="password">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-lock"></i>
                    <el-input
                        v-model="loginForm.password"
                        placeholder="密码"
                        clearable
                        :type="showPassword ? 'text' : 'password'"
                        auto-complete="new-password"
                        @keyup.enter.native="handleLogin"
                    >
                    <i slot="suffix" @click="switchPassword">
                        <img
                            v-if="showPassword"
                            :src="showView"
                            class="input-icon"
                            alt="show"/>
                        <img
                            v-else
                            :src="closeView"
                            class="input-icon"
                            alt="close"/>
                        </i>
                    </el-input>
                </div>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item prop="code">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-postcard"></i>
                    <el-input
                        v-model="loginForm.code"
                        placeholder="验证码"
                        clearable
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <!-- 网络图片验证码 -->
                    <img
                        v-if="loginForm.codeUrl"
                        slot="suffix"
                        class="login-form-item-code"
                        :src="loginForm.codeUrl"
                        alt="code"
                        @click="handlePictureVerificationCodeClick"
                    >
                    <!-- 静态图片验证码占位 -->
                    <img
                        v-else
                        slot="suffix"
                        class="login-form-item-code"
                        :src="imgVerificationCode"
                        alt="code"
                    >
                </div>
            </el-form-item>
            <!-- 记住密码 -->
            <div class="remember-password">
                <el-checkbox v-if="templateInfo.rememberme == '1'" v-model="loginForm.rememberPassword">记住登录状态</el-checkbox>
                <el-button type="text" class="text" @click="forgottenPassword">忘记密码</el-button>
            </div>
        </el-form>
        <!-- 短信验证码登录 -->
        <el-form
            v-else-if="loginForm.curLoginMethod === '4'"
            ref="loginForm"
            class="login-form login-form_smsCode"
            :model="loginForm"
            :rules="loginRules"
            label-width="0px">
            <!-- 手机号 -->
            <el-form-item prop="phoneNumber">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-user"></i>
                    <el-input v-model="loginForm.phoneNumber" placeholder="手机号" clearable></el-input>
                </div>
            </el-form-item>
            <!-- 短信验证码 -->
            <el-form-item prop="smsCode">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-message"></i>
                    <el-input
                        v-model="loginForm.smsCode"
                        placeholder="短信验证码"
                        clearable
                        auto-complete="new-password"
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <div class="login-form-input-sms-code">
                        <span v-if="loginForm.smsCodeCountdown > 0">{{ loginForm.smsCodeCountdown }}秒</span>
                        <span v-else @click="handleSmsVerificationCodeClick">获取验证码</span>
                    </div>
                </div>
            </el-form-item>
            <!-- 图片验证码 -->
            <el-form-item prop="code">
                <div class="login-form-item login-form-input">
                    <i class="login-form-input-icon el-icon-postcard"></i>
                    <el-input
                        v-model="loginForm.code"
                        placeholder="验证码"
                        clearable
                        @keyup.enter.native="handleLogin"
                    ></el-input>
                    <!-- 网络图片验证码 -->
                    <img
                        v-if="loginForm.codeUrl"
                        slot="suffix"
                        class="login-form-item-code"
                        :src="loginForm.codeUrl"
                        alt="code"
                        @click="handlePictureVerificationCodeClick"
                    >
                    <!-- 静态图片验证码占位 -->
                    <img
                        v-else
                        slot="suffix"
                        class="login-form-item-code"
                        :src="imgVerificationCode"
                        alt="code"
                    >
                </div>
            </el-form-item>
            <div class="remember-password" v-if="templateInfo.rememberme == '1'">
                <el-checkbox v-model="loginForm.rememberPassword">记住登录状态</el-checkbox>
            </div>
        </el-form>
        <!-- 登录按钮 -->
        <div
            class="login-button"
            :class="{ 'login-button_loading': loginButtonLoading }"
            @click="handleLogin">
            <i v-show="loginButtonLoading" class="el-icon-loading"></i>
            <span>登录</span>
        </div>
        <!-- 其他登录 -->
        <div class="other-login">
            <img
                v-if="templateInfo.loginMethod.includes('2')"
                class="other-login-btn"
                :src="imgWeChat"
                alt="weChat"
                @click="handleOtherLoginClick('WeChat')"
            >
            <img
                v-if="templateInfo.loginMethod.includes('3')"
                class="other-login-btn"
                :src="imgDingTalk"
                alt="dingTalk"
                @click="handleOtherLoginClick('DingTalk')"
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        },
        loginRules: {
            type: Object
        },
        loginButtonLoading: {
            type: Boolean
        }
    },
    data() {
        return {
            showPassword: false
        }
    },
    computed: {
        imgVerificationCode() {
            return require('@/assets/images/loginPageTemplate/theme2/login_box_verification_code.png')
        },
        imgWeChat() {
            return require('@/assets/images/loginPageTemplate/theme2/login_box_qrcode_wechat.png')
        },
        imgDingTalk() {
            return require('@/assets/images/loginPageTemplate/theme2/login_box_qrcode_dingtalk.png')
        },
        closeView () {
            return require('@/assets/images/login/close-view.png')
        },
        showView () {
            return require('@/assets/images/login/show-view.png')
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleLoginMethodSwitch(loginMethod) {
            this.$eventDispatch('loginMethodSwitch', loginMethod);
            this.clearValidate()
        },
        // 清楚表单校验提示
        clearValidate() {
            this.$refs.loginForm.clearValidate();
        },
        handleSmsVerificationCodeClick() {
            this.$eventDispatch('smsVerificationCodeClick', this.smsCodeCountdown);
        },
        handlePictureVerificationCodeClick() {
            this.$eventDispatch('pictureVerificationCodeClick', this.loginForm.codeUrl);
        },
        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                // console.log("[handleLogin]valid:", valid)
                if (valid) {
                    this.$eventDispatch('login', this.loginForm);
                }
            })
        },
        handleOtherLoginClick(type) {
            this.$eventDispatch('otherLoginClick', type);
        },
        forgottenPassword () {
            this.$emit('forgottenPassword')
        },
        switchPassword () {
            this.showPassword = !this.showPassword;
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.login-main {
    width: 100%;
    // min-height: calc(440 * #{$coefficient});
    height: 100%;
    background: #FFFFFF;
    border-radius: calc(16 * #{$coefficient});
    padding: calc(27 * #{$coefficient}) calc(30 * #{$coefficient}) calc(15 * #{$coefficient});
    box-sizing: border-box;
    overflow: hidden;
    .login-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        margin-bottom: calc(27 * #{$coefficient});

        .header-item {
            font-size: calc(20 * #{$coefficient});
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
        }
        .header-item_activated {
            font-size: calc(24 * #{$coefficient});
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #377FFF;
        }

        .header-line {
            font-size: calc(16 * #{$coefficient});
            color: #333333;
            line-height: 1;
        }
    }

    .login-form {
        ::v-deep .el-form-item {
            margin-bottom: 0;

            +.el-form-item {
                margin-top: calc(24 * #{$coefficient});
            }

            .el-form-item__error {
                padding-top: calc(2 * #{$coefficient});
                font-size: calc(12 * #{$coefficient});
            }
        }

        .login-form-item {
            background: #F7F8FA;
            height: calc(60 * #{$coefficient});
            box-sizing: border-box;
            display: flex;
            align-items: center;
            border-radius: 10px;

            .login-form-input-icon {
                color: #CBCBCB;
                font-size: calc(22 * #{$coefficient});
                margin-left: calc(20 * #{$coefficient});
                margin-right: calc(20 * #{$coefficient});
            }

            ::v-deep .el-input {
                font-size: calc(18 * #{$coefficient});

                .el-input__inner {
                    border: unset;
                    padding: 0;
                    height: auto;
                    line-height: 1;
                    background: transparent;
                }

                // 解决回填后带背景色问题
                .el-input__inner:-webkit-autofill,
                .el-input__inner:-webkit-autofill:hover,
                .el-input__inner:-webkit-autofill:focus,
                .el-input__inner:-webkit-autofill:active {
                    -webkit-transition-delay: 99999s;
                    -webkit-transition: color 99999s ease-out,
                    background-color 99999s ease-out;
                }

                .el-input__suffix {
                    .el-input__suffix-inner {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        height: 100%;
                    }

                    .el-input__icon {
                        width: auto;
                        line-height: 1;
                        font-size: calc(16 * #{$coefficient});
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        +.el-input__icon {
                            margin-left: calc(10 * #{$coefficient});
                        }
                    }

                    .el-input__validateIcon {
                        display: none;
                    }
                }
            }
            ::v-deep .el-input--suffix {
                .el-input__inner {
                    padding-right: calc(36 * #{$coefficient});
                }
            }

            .login-form-item-code {
                width: calc(103 * #{$coefficient});
                height: calc(36 * #{$coefficient});
                object-fit: contain;
                display: inline-block;
                padding-left: calc(20 * #{$coefficient});
                border-left: 1px solid #CBCBCB;
                margin-right: calc(12 * #{$coefficient});
                cursor: pointer;
            }

            .login-form-input-sms-code {
                flex-shrink: 0;
                min-width: calc(60 * #{$coefficient});
                font-size: calc(18 * #{$coefficient});
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 2.1;
                color: #88888A;
                padding-left: calc(26 * #{$coefficient});
                border-left: 1px solid #CBCBCB;
                margin-right: calc(26 * #{$coefficient});
                cursor: pointer;
            }

            .input-icon {
                width: calc(21 * #{$coefficient});
                margin-top: calc(10 * #{$coefficient});
                margin-right: calc(10 * #{$coefficient});
            }
        }

        .remember-password {
            display: flex;
            justify-content: space-between;
            margin-top: calc(16 * #{$coefficient});
            min-height: calc(20 * #{$coefficient});
            line-height: 1;

            ::v-deep .el-checkbox {
                display: flex;
                flex-direction: row;
                align-items: center;

                .el-checkbox__inner {
                    width: calc(16 * #{$coefficient});
                    height: calc(16 * #{$coefficient});
                    min-width: 9px;
                    min-height: 9px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &::after {
                        top: calc(2 * #{$coefficient});
                        left: calc(5 * #{$coefficient});
                        width: calc(3 * #{$coefficient});
                        height: calc(7 * #{$coefficient});
                    }
                }
                .el-checkbox__label {
                    padding-left: calc(10 * #{$coefficient});
                    line-height: calc(19 * #{$coefficient});
                    font-size: calc(16 * #{$coefficient});
                }
            }
            .text {
                /deep/ span {
                    font-size: calc(16 * #{$coefficient}) !important;
                }
            }
        }
    }
    .login-form_password {
        margin-bottom: calc(10 * #{$coefficient});
    }
    .login-form_smsCode {
        margin-bottom: calc(16 * #{$coefficient});
    }

    .login-button {
        height: calc(52 * #{$coefficient});
        background: #377FFF;
        border-radius: calc(30 * #{$coefficient});
        line-height: calc(52 * #{$coefficient});
        text-align: center;
        font-size: calc(18 * #{$coefficient});
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;

        .el-icon-loading {
            margin-right: calc(6 * #{$coefficient});
        }
    }
    .login-button_loading {
        cursor: not-allowed;
        pointer-events: none;
        filter: opacity(30%);
        -webkit-filter: opacity(30%);
    }

    .other-login {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: calc(6 * #{$coefficient});

        .other-login-btn {
            width: calc(64 * #{$coefficient});
            height: calc(64 * #{$coefficient});
            object-fit: fill;
            display: inline-block;
            cursor: pointer;

            +.other-login-btn {
                margin-left: calc(26 * #{$coefficient});
            }
        }
    }
}
</style>
