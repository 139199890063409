<template>
    <div class="qr-code-login login-main">
        <div class="login-qrcode">
            <div
                v-if="templateInfo.loginMethod.includes('2')"
                class="login-qrcode-btn"
                @click="handleOtherLoginClick('WeChat')">
                <img :src="imgWeChat" alt="weChat">
                <span>微信扫一扫</span>
            </div>
            <div
                v-if="templateInfo.loginMethod.includes('3')"
                class="login-qrcode-btn"
                @click="handleOtherLoginClick('DingTalk')">
                <img :src="imgDingTalk" alt="dingTalk">
                <span>钉钉扫一扫</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QRCodeLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        }
    },
    data() {
        return {}
    },
    computed: {
        imgWeChat() {
            return require('@/assets/images/loginPageTemplate/theme2/login_box_qrcode_wechat.png')
        },
        imgDingTalk() {
            return require('@/assets/images/loginPageTemplate/theme2/login_box_qrcode_dingtalk.png')
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleOtherLoginClick(type) {
            this.$eventDispatch('otherLoginClick', type);
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.login-main {
    width: 100%;
    height: calc(440 * #{$coefficient});
    background: #FFFFFF;
    border-radius: calc(16 * #{$coefficient});
    box-sizing: border-box;
    overflow: hidden;
    .login-qrcode {
        width: 100%;
        margin-top: calc(146 * #{$coefficient});
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .login-qrcode-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            +.login-qrcode-btn {
                margin-left: calc(110 * #{$coefficient});
            }

            img {
                width: calc(86 * #{$coefficient});
                height: calc(86 * #{$coefficient});
                object-fit: contain;
                display: inline-block;
            }

            span {
                font-size: calc(16 * #{$coefficient});
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #05042A;
                margin-top: calc(17 * #{$coefficient});
            }
        }
    }
}
</style>
